import React from 'react'
import { Spin } from 'antd'
const WLoading = ({ size = "large", children, spinning }) => {
  return (
    <>
      {children ?
        <Spin size={size} spinning={spinning} wrapperClassName="page-loading-box-haschild">{children}</Spin> :
        <div className="page-loading-box"><Spin size={size} /></div>}
    </>
  )
}
export default WLoading