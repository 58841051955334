import React, { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { Breadcrumb, Select, Tabs, message, Tooltip } from "antd"
import { navigate } from "gatsby"
import { sortBy, get, groupBy } from "lodash"
import axios from "axios"

import { ProductLabels } from "../utils/config.js"
import {
  getVersionDescLang,
  compareVersions,
  formatCPU,
} from "../utils/methods.js"

import Seo from "../components/seo"
import Icon from "../components/iconfontCpt"
import Layout from "../components/layout"
import WLoading from "../components/wLoading"

import "../style/mogdbdownload.scoped.scss"

const { TabPane } = Tabs
const { Option } = Select
// 初始值设置
const ProductDownLoad = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === "en" ? "/en" : ""

  const apiPrefix =
    process.env && process.env.NODE_ENV === "development"
      ? "http://jenkins.mogdb.enmotech.com:7004/api"
      : "/api"
  const downloadPrefix = "https://cdn-mogdb.enmotech.com/"

  const productKey = pageContext.key
  const pageKey = ProductLabels[productKey]

  useEffect(() => {
    getVersions()
  }, [])

  const [loading, setLoading] = useState(false) // 版本loading

  const [version, setVersion] = useState("") // 当前选中版本
  const [curVersionLang, setCurVersionLang] = useState("")
  const [curVersionDate, setCurVersionDate] = useState("")
  const [os, setOs] = useState("") // 当前选中os
  const [cpu, setCpu] = useState("") // 当前选中cpu

  const [packages, setPackages] = useState([]) // 原始数据
  const [versionListData, setVersionList] = useState([])
  const [cpuList, setCpuList] = useState([])
  const [downloadList, setDownloadList] = useState([])

  // 获取当前产品下的版本信息
  async function getVersions() {
    setLoading(true)
    const res = await axios.get(apiPrefix + "/public/products")
    if (res && res.data && res.data.code === 200) {
      const productData = get(res, "data.data.list", []).find(
        p => p.product.toLowerCase() === productKey
      )

      if (!productData) return
      // 根据产品ID获取版本信息
      const versionsRes = await axios.get(
        `${apiPrefix}/public/versions?pageLimit=1000&pageNum=1&productId=${productData.id}&isLastVersion=true`
      )
      if (versionsRes && versionsRes.data && versionsRes.data.code === 200) {
        let versions = get(versionsRes, "data.data.list", [])
        if (versions.length === 0) return
        versions = versions.sort(compareVersions).reverse()
        setVersionList(versions)

        const firstItem = versions[0] || {}
        handleVersionChange(firstItem.id)
        setCurVersionLang(getVersionDescLang(firstItem, pageLang))
        setCurVersionDate(firstItem.releaseDate)
      } else {
        setLoading(false)
        message.error((versionsRes.data && versionsRes.data.message) || "error")
      }
    } else {
      setLoading(false)
      message.error((res.data && res.data.message) || "error")
    }
  }

  // 处理版本变更
  function handleVersionChange(id) {
    setVersion(id)
    getPackages(id)
    // 重置
    handleOsChange("")

    if (versionListData.length === 0) return
    const curVersion = versionListData.find(v => v.id === id) || {}
    setCurVersionLang(getVersionDescLang(curVersion, pageLang))
    setCurVersionDate(curVersion.releaseDate)
  }

  // 根据版本获取安装包
  async function getPackages(versionID) {
    const res = await axios.get(
      `${apiPrefix}/public/versions/${versionID}/packages?pageLimit=1000&pageNum=1`
    )
    setLoading(false)
    if (res && res.data && res.data.code === 200) {
      const packages = get(res, "data.data", [])
      const packagesTree = {}
      packages.forEach(item => {
        const osList = [item.os, ...item.supportOS]
        osList.forEach(p => {
          // uos 特殊处理
          if (p.os.toLowerCase().indexOf("uos") > -1) {
            p.cpu = p.os + " " + formatCPU(p.cpu)
            p.os = "UOS"
            p.class = "UOS"
          } else {
            if (p.class !== p.os) {
              p.cpu = p.class ? p.os + " " + formatCPU(p.cpu) : p.cpu
              p.class = p.class || p.os
            }
          }

          if (!packagesTree[p.class]) {
            packagesTree[p.class] = {}
          }
          if (!packagesTree[p.class][p.cpu]) {
            packagesTree[p.class][p.cpu] = {}
          }
          packagesTree[p.class][p.cpu] = item.packages
        })
      })
      setPackages(packagesTree)
    } else {
      message.error((res.data && res.data.message) || "error")
    }
  }

  // 处理os变更
  function handleOsChange(os) {
    setOs(os)

    // 重置cpu & list
    handleCpuChange("")
    const cpus = Object.keys(packages[os] || {})
    setCpuList(cpus)
  }

  // 处理cpu变更
  function handleCpuChange(cpu) {
    setCpu(cpu)

    const TypeArr = ["Server", "Toolkits", "Plugins"]
    const groupData = groupBy(
      (packages[os] && packages[os][cpu]) || [],
      "fileType"
    )
    const groupKeys = sortBy(Object.keys(groupData), p =>
      TypeArr.findIndex(t => t.toLowerCase() === p.toLowerCase())
    )
    const downloads = groupKeys
      .map(type => ({ type, list: groupData[type] }))
      .sort(
        (a, b) =>
          ["Server", "Plugins", "Toolkits"].indexOf(a.type) -
          ["Server", "Plugins", "Toolkits"].indexOf(b.type)
      )
    setDownloadList(downloads)
  }

  // 格式化下载包
  function formatPackagesNode(data) {
    if (!data || data.length === 0) return
    const formatData = data.map(d => {
      return {
        ...d,
        _langDes: getVersionDescLang(d, pageLang),
        isEncrypted: d.md5 || d.sha1 || d.sha256,
      }
    })
    return formatData.map(p => (
      <div className="download-card" key={p.id}>
        <div className="download-label">
          {p.fileName}
          {p._langDes && <p>{p._langDes}</p>}
        </div>
        {p.downloadUrl && (
          <a className="download-link" href={downloadPrefix + p.downloadUrl}>
            <Icon type="icon-download" size={12} />
            <span className="more-text">
              <FormattedMessage id="download.download" />
            </span>
          </a>
        )}
        {p.isEncrypted && (
          <Tooltip placement="left" title={formatMda(p)}>
            <span className="download-link">
              <Icon type="icon-sha256" size={12} />
            </span>
          </Tooltip>
        )}
      </div>
    ))
  }

  function formatMda(d) {
    return (
      <div className="md5-box">
        {d.md5 && (
          <div className="md5-item">
            <div className="md5-label">MD5:</div>
            <div className="md5-content">{d.md5}</div>
          </div>
        )}
        {/* {d.sha1 && (
          <div className="md5-item">
            <div className="md5-label">SHA1:</div>
            <div className="md5-content">{d.sha1}</div>
          </div>
        )} */}
        {d.sha256 && (
          <div className="md5-item">
            <div className="md5-label">SHA256:</div>
            <div className="md5-content">{d.sha256}</div>
          </div>
        )}
      </div>
    )
  }

  // 复制代码
  function copy(className) {
    const copyEle = document.querySelector(className) // 获取要复制的节点
    const range = document.createRange() // 创造range
    window.getSelection().removeAllRanges() //清除页面中已有的selection
    range.selectNode(copyEle)
    window.getSelection().addRange(range) // 执行选中元素
    const copyStatus = document.execCommand("Copy") // 执行copy操作
    // 对成功与否定进行提示
    if (copyStatus) {
      message.success("复制成功")
    } else {
      console.log("复制失败")
    }
    window.getSelection().removeAllRanges() //清除页面中已有的selection
  }
  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true}>
      <Seo title={`${pageKey}: ${pageKey} Downloads`} />
      <div className="banner-container">
        <div className="banner-box">
          {pageKey} <FormattedMessage id="download.download" />
        </div>
      </div>
      <div className="download-container">
        <div className="title">
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href={`${pagePrefix}/download/`}>
                <FormattedMessage id="header.download" />
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <FormattedMessage id="download.downloadDetail" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="privacy-tip">
          <Icon type="icon-explain" size={14} />
          <span>
            <FormattedMessage id="download.licenseLabel1" />
          </span>
          <a href="/privacy/" target="_blank" className="text-btn">
            &nbsp;
            <FormattedMessage id="download.licenseLabel2" />
          </a>
        </div>

        <div className="tab">
          <Tabs defaultActiveKey="1">
            {productKey === "mogdb" && (
              <TabPane
                tab={<FormattedMessage id="download.autoInstall" />}
                key="1"
              >
                <div className="contain">
                  <div className="contain-box">
                    <div className="header">
                      <FormattedMessage id="download.ptk" />
                    </div>
                    <div className="code">
                      <span>
                        ${" "}
                        <span className="order">
                          curl --proto '=https' --tlsv1.2 -sSf
                          https://cdn-mogdb.enmotech.com/ptk/install.sh | sh
                        </span>
                      </span>
                      <p onClick={() => copy(".order")}>
                        <Icon type="icon-copy" size={12} />
                        <span className="more-text">
                          <FormattedMessage id="download.copy" />
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="contain-box">
                    <div className="header">
                      <FormattedMessage id="download.config" />
                    </div>
                    <div className="code">
                      <span>
                        ${" "}
                        <span className="order1">
                          ptk template --local &gt; config.yaml
                        </span>
                      </span>
                      <p onClick={() => copy(".order1")}>
                        <Icon type="icon-copy" size={12} />
                        <span className="more-text">
                          <FormattedMessage id="download.copy" />
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="contain-box">
                    <div className="header">
                      <FormattedMessage id="download.mogdb" />
                    </div>
                    <div className="code">
                      <span>
                        ${" "}
                        <span className="order2">
                          ptk install -f config.yaml
                        </span>
                      </span>
                      <p onClick={() => copy(".order2")}>
                        <Icon type="icon-copy" size={12} />
                        <span className="more-text">
                          <FormattedMessage id="download.copy" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tip">
                  <div className="header">
                    <Icon type="icon-tips" size={12} />
                    <span className="more-text">Tips:</span>
                  </div>
                  <div className="content">
                    <p>
                      <FormattedMessage id="download.tip1" />
                    </p>
                    <p>
                      <FormattedMessage id="download.tip2" />
                      <a
                        href="https://docs.mogdb.io/zh/ptk/latest/quick-start"
                        target="_blank"
                      >
                        <FormattedMessage id="download.tip3" />
                      </a>
                    </p>
                  </div>
                </div>
              </TabPane>
            )}
            <TabPane
              tab={<FormattedMessage id="download.manuallyInstall" />}
              key={productKey === "mogdb" ? "2" : "1"}
            >
              <WLoading spinning={loading}>
                <div className="mobile-version-box">
                  <div className="item-label">
                    <FormattedMessage id="download.version" />：
                    <div
                      className="history-download"
                      onClick={() =>
                        navigate(`${pagePrefix}/downloads/${productKey}/all`)
                      }
                    >
                      <Icon type="icon-see" size={10} />
                      <span>
                        <FormattedMessage id="download.downloadHistory" />
                      </span>
                    </div>
                  </div>
                  <div className="cpu-box">
                    {versionListData.map(item => (
                      <div
                        className={`cpu-card ${
                          version === item.id ? "is-active" : ""
                        }`}
                        onClick={() => handleVersionChange(item.id)}
                        key={item.id}
                      >
                        {item.version}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="version-drop">
                  <span className="version-drop-label item-label">
                    <FormattedMessage id="download.version" />
                  </span>
                  <Select
                    value={version}
                    style={{ width: 250 }}
                    onChange={handleVersionChange}
                  >
                    {versionListData.map(item => (
                      <Option value={item.id} key={item.id}>
                        {item.version}
                      </Option>
                    ))}
                  </Select>
                  <div
                    className="history-download"
                    onClick={() =>
                      navigate(`${pagePrefix}/downloads/${productKey}/all`)
                    }
                  >
                    <Icon type="icon-see" size={12} />
                    <span>
                      <FormattedMessage id="download.downloadHistory" />
                    </span>
                  </div>
                </div>

                {(curVersionDate || curVersionLang) && (
                  <div className="version-tips">
                    {curVersionDate && (
                      <div className="download-tip version-tip">
                        <FormattedMessage id="productDoc.versionDateTip" />
                        {curVersionDate}
                      </div>
                    )}
                    {curVersionLang && (
                      <div className="download-tip version-tip">
                        <FormattedMessage id="productDoc.versionTip" />
                        {curVersionLang}
                      </div>
                    )}
                  </div>
                )}

                <div className="item-label">
                  <FormattedMessage id="download.chooseOs" />：
                </div>
                <div className="os-box">
                  {sortBy(Object.keys(packages)).map(item => (
                    <div
                      className={`os-card ${item.toLowerCase()} ${
                        os === item ? "is-active" : ""
                      }`}
                      onClick={() => handleOsChange(item)}
                      key={item}
                    ></div>
                  ))}
                </div>
                {/* cpu以及下载内容存在时才展示 */}
                {cpuList && cpuList.length > 0 && (
                  <>
                    <div className="item-label">
                      <FormattedMessage id="download.chooseCpu" />：
                    </div>
                    <div className="cpu-box">
                      {cpuList.map(item => (
                        <div
                          className={`cpu-card ${
                            cpu === item ? "is-active" : ""
                          }`}
                          onClick={() => handleCpuChange(item)}
                          key={item}
                        >
                          {formatCPU(item)}
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {downloadList.length > 0 && (
                  <>
                    <div className="item-label">
                      <FormattedMessage id="download.downloadInstall" />：
                    </div>
                    {cpu &&
                      formatCPU(cpu).toLowerCase().indexOf("x86-64") > -1 && (
                        <div className="download-tip">
                          <FormattedMessage id="download.downloadX86Tip" />
                        </div>
                      )}
                    <div className="download-box">
                      {downloadList.map(({ type, list }) => (
                        <div key={type}>
                          <div className="packageTitle">
                            <FormattedMessage
                              id={`download.${type.toLowerCase()}`}
                            />
                            ：
                          </div>
                          {formatPackagesNode(list)}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </WLoading>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Layout>
  )
}
export default ProductDownLoad
